import { useLocation } from "react-router-dom";
import React from "react";

export const isEmpty = (val) => {
  if (val.constructor === Number) {
    return false;
  }

  if (val.constructor === Object) {
    return Object.entries(val).length === 0;
  }
  if (val.constructor === String) {
    return val === undefined || val === null || val.length === 0;
  }

  throw new Error("Unsupported isEmpty type");
};

export const isFalsy = (val) => {
  return !val;
};

export const isTruthy = (val) => {
  return !!val;
};

export const roundToDecimal = (number, numDecimals) => {
  const multiplier = 10 ** numDecimals;
  return Math.round(number * multiplier) / multiplier;
};

export const stub = () => {};

export const executeImmediatelyWithInterval = (func, funcArgs, interval) => {
  try {
    func(...funcArgs); // immediate execution
  } catch (err) {
    // ignore intentionally
  }

  setInterval(() => {
    // periodic execution
    try {
      func(...funcArgs);
    } catch (err) {
      // ignore intentionally
    }
  }, interval);
};

export const delay = (millis) => {
  return new Promise((resolve, _reject) => setTimeout(resolve, millis));
};

export const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const hyphenToSpace = (str) => {
  if (str == null) {
    return null;
  }
  // convert all non-escaped hyphens to space
  let res = str.replace(/(?<!\\)-/g, " ");
  // Unescape all escaped hyphens
  res = res.replace(/\\-/g, "-");
  return res;
};

export const spaceToHyphen = (str) => {
  if (str == null) {
    return null;
  }

  // Escape all hyphens
  let res = str.replace(/-/g, "\\-");
  // Replace all spaces with hyphens
  res = res.replace(/\s+/g, "-");
  return res;
};

export const scrollToRef = (ref) => {
  if (ref != null) {
    ref.current.scrollIntoView({ block: "end", behavior: "smooth" });
  }
};

export const deleteCookie = (key) => {
  // to delete a cookie, simply put it beyond expiration
  document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

export const setCookie = (key, value) => {
  // because idToken and acccessToken is valid for 1 day at most, we'd have to resfresh the token anyways
  // technically this expiration only has to be longer than 1 day, but oh well we will reset this cookie upon login, logout, signup, refresh actions
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + 400); // new standards only allow max-age for non-session cookies to be 400 days

  const cookieString = `${key}=${value}; expires=${expirationDate.toUTCString()}; path=/`;

  document.cookie = cookieString;
};

export const isStringInteger = (str) => {
  return Number.isInteger(Number(str));
};

export const allFormErrorsNull = (errors) => {
  return Object.keys(errors).every((key) => {
    const value = errors[key];
    if (value && typeof value === "object") {
      // If the value is an object, check its properties recursively
      return allFormErrorsNull(value);
    }
    // check for null or false
    return value === null || value === false;
  });
};

export const capitalizeFirstLetterOnly = (inputString) => {
  const lowerCasedString = inputString.toLowerCase();
  return lowerCasedString.charAt(0).toUpperCase() + lowerCasedString.slice(1);
};

export const roundToOneDecimal = (num) => {
  const roundedNum = parseFloat(num).toFixed(1); // This will round the number to 1 decimal place
  return roundedNum > 0 ? `+${roundedNum}%` : `${roundedNum}%`;
};

export const filterStringsAndJoin = (list, delimiter, emptyState) => {
  return (
    list
      .filter(Boolean) // filter out nulls and undefined
      .filter((item) => item.trim() !== "") // filter out empty strings
      .join(delimiter) ?? emptyState
  );
};

export const abbreviateNumber = (number, currency, decimal) => {
  const prefix = currency ?? "";
  let suffix = "";

  if (number >= 1000000) {
    suffix = "M";
    number = number / 1000000;
  } else if (number >= 1000) {
    suffix = "K";
    number = number / 1000;
  }

  const num =
    decimal != null && decimal > 0
      ? number.toFixed(decimal)
      : parseInt(number.toFixed(0));

  return prefix + num + suffix;
};
